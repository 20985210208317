// Copyright (C) 2020 Deconve Technology. All rights reserved.
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import moment from 'moment';
import store from '../store';
import { blobToDataUrl } from '../utils/data';

interface Tag {
  id: string;
  name: string;
  value: string;
  color: string;
}

interface Model {
  id: string;
  package_version: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
}

// We use this method to help us manipulate the image content. When using <img> to fetch the image
// content from an url, the browsers disables the cors and does not allow us to access the image
// content because it is marked as tainted. See the following link for more details:
// https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image#security_and_tainted_canvases
export function downloadImage(url: string): Promise<string> {
  return new Promise((resolve) => {
    if (url) {
      const requestOptions: AxiosRequestConfig = {
        url,
        method: 'get',
        responseType: 'blob',
      };

      axios(requestOptions)
        .then((response) => blobToDataUrl(response as AxiosResponse))
        .then((image) => resolve(image))
        .catch(() => resolve(''));
    } else {
      resolve('');
    }
  });
}

function getDemoModels(): Model[] {
  const model: Model = {
    id: '00000000-0000-0000-0000-000000000000',
    // eslint-disable-next-line @typescript-eslint/camelcase
    package_version: '0.0.0',
    // eslint-disable-next-line @typescript-eslint/camelcase
    created_at: moment.utc().toISOString(),
    // eslint-disable-next-line @typescript-eslint/camelcase
    updated_at: moment.utc().toISOString(),
  };

  return [model];
}

export function getModels(personId: string): Promise<Model[]> {
  return new Promise((resolve) => {
    const { authorizationToken, isDemoMode } = store.getters;

    const requestOptions: AxiosRequestConfig = {
      url: `faceid/models/?person_id=${personId}`,
      method: 'GET',
      baseURL: process.env.VUE_APP_DECONVE_API_URL,
      headers: {
        Authorization: authorizationToken,
      },
    };

    if (isDemoMode) {
      resolve(getDemoModels());
    } else {
      axios(requestOptions).then((response) => {
        const { data } = response;

        resolve(data);
      });
    }
  });
}

export function getUserInfo(id: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const { authorizationToken } = store.getters;

    const requestOptions: AxiosRequestConfig = {
      baseURL: process.env.VUE_APP_DECONVE_AUTH_URL,
      url: `info/${id}/`,
      method: 'GET',
      headers: {
        Authorization: authorizationToken,
      },
    };

    axios(requestOptions).then((response) => {
      const { data } = response;

      resolve(data);
    }).catch(() => {
      reject();
    });
  });
}
